import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import throttle from 'lodash/throttle'
import classNames from 'classnames'
import shallow from 'zustand/shallow'
import useResizeObserver from 'use-resize-observer'
import { AnimatePresence, motion } from 'framer-motion'
// @ts-ignore
import textfit from 'textfit'
import { useInView } from 'react-intersection-observer'

import { Controls } from '@/components/actions/Controls'
import { useStore } from '@/store'
import { attrs, FontDescriptor, getFontStyle, toAlphabetic } from '@/utils'
import { FontName } from '../../font/FontName'
import { Letter } from '@/components/common'
import { ViewMore } from '@/components/actions/ViewMore'
import { pangrams } from '@/data/text'
import { useObserverRefreshValue } from '@/hooks/use-defer-until-observed'
import { letterSectionAttribute } from '@/data/classes'
import { useScrollToLetter } from '@/hooks/use-scroll-to-letter'
import styles from './FittedView.module.css'
import {
  getBackgroundImageByFontId,
  getColorSchemeByFontId,
} from '@/store/selectors'
import { usePageHotkey } from '@/hooks/use-page-hotkey'
import { BackgroundScheme, ColorScheme } from '@/data/colors'
import { applyRefs } from '@/utils/apply-ref'
import { usePrevious } from '@/hooks/use-hook'
import { FontWait } from '@/components/Text'
import { config } from '@/config'
import { Bus } from '@/events'
import { LazyMotionImage } from '@/components/LazyImage'

export const FittedViewLayout: FC = () => {
  const { fonts, listOrder, fontsRandomized } = useStore(
    (state) => ({
      fonts: state.fonts,
      listOrder: state.listOrder,
      fontsRandomized: state.fontsRandomized,
    }),
    shallow
  )

  const [isVisible, setVisible] = useState(false)

  const { portal } = useScrollToLetter()

  const representatives = useMemo(() => {
    let result

    if (listOrder === 'random') {
      result = fontsRandomized
        .map((index) => fonts.all[index])
        .filter((font) => {
          return fonts.byFamily[font.family].representative.id === font.id
        })
    } else {
      result = Object.values(fonts.byFamily).map(
        (family) => family.representative
      )
    }

    return result
  }, [listOrder, fonts, fontsRandomized])

  const alphabetic = useMemo(
    () => toAlphabetic(representatives),
    [representatives]
  )

  usePageHotkey()

  useEffect(() => {
    setTimeout(() => {
      setVisible(true)
    }, 700)
  }, [])

  let content: ReactNode

  if (listOrder === 'random') {
    content = (
      <div className="px-2" style={{ opacity: isVisible ? 1 : 0 }}>
        <Letter className="pb-4 pt-3 px-4 left-0 bg-white dark:bg-dark2-500 z-30">
          Random order
        </Letter>
        {representatives.map((font, i, arr) => {
          if (fonts.byFamily[font.family] == undefined) {
            // TODO: Handle this case
          }

          return (
            <Item
              key={font.id}
              font={font}
              members={fonts.byFamily[font.family].fonts}
              isLast={i === arr.length - 1}
            />
          )
        })}
      </div>
    )
  } else {
    content = (
      <div style={{ opacity: isVisible ? 1 : 0 }}>
        {Object.entries(alphabetic).map(([letter, fontFamily]) => {
          if (fontFamily.length === 0) return null
          return (
            <div key={letter}>
              <div
                {...{ [letterSectionAttribute]: letter.toLowerCase() }}
                className="-top-navH relative h-[1px]"
              />
              <Letter className="pb-4 pt-3 px-6 sticky w-full top-navH left-0 bg-white dark:bg-dark2-500 z-30">
                {letter.toUpperCase()}
              </Letter>
              <div className="px-2 md:px-2">
                <div className="flex flex-nowrap">
                  {/* {alphabet.map((l) => (
                    <Letter
                      key={l}
                      className={classNames('opacity-0 pl-6 pt-3', {
                        'opacity-100 absolute l-0': l === letter,
                      })}
                    >
                      {l.toUpperCase()}
                    </Letter>
                  ))} */}
                </div>
              </div>
              <div className="mx-2">
                {fontFamily.map((font, i, arr) => {
                  if (fonts.byFamily[font.family] == undefined) {
                    // TODO: Handle this case
                  }
                  return (
                    <Item
                      key={font.id}
                      font={font}
                      members={fonts.byFamily[font.family].fonts}
                      isLast={i === arr.length - 1}
                    />
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div className="mb-60">
      {portal}
      {content}
    </div>
  )
}

const layoutClass = 'min-h-[105px] lg:min-h-[175px]'

const Item: FC<{
  font: FontDescriptor
  members: FontDescriptor[]
  isLast: boolean
}> = ({ font, members, isLast }) => {
  const [isMoreVisible, setMoreVisible] = useState(false)

  const membersWithoutRepresentative = members.filter((m) => m.id !== font.id)

  const viewMoreButton = (
    <div className="text-center my-4">
      <ViewMore onClick={() => setMoreVisible((b) => !b)}>
        {isMoreVisible ? (
          'Hide styles'
        ) : (
          <>
            {membersWithoutRepresentative.length} style
            {membersWithoutRepresentative.length > 1 ? 's' : ''} available
          </>
        )}
      </ViewMore>
    </div>
  )

  return (
    <div {...attrs(font)}>
      <div className={classNames(styles.item, layoutClass)}>
        <div className="border-t border-borderLight-500 dark:border-borderDark-500 mx-4" />
        <div className="top w-full flex items-center relative">
          <div className="w-full">
            <ItemContent className="top" font={font} />
            <AnimatePresence>
              {isMoreVisible && (
                <motion.div
                  transition={{ bounce: 0 }}
                  initial={{ height: 0 }}
                  animate={{ height: 'auto' }}
                  exit={{ height: 0 }}
                  className="overflow-hidden"
                >
                  {membersWithoutRepresentative.map((font, index) => {
                    return (
                      <div key={font.id}>
                        <div className="border-b border-dashed border-borderLight-500 dark:border-borderDark-500 mx-4" />
                        <ItemContent
                          isNested
                          showFullName
                          font={font}
                          nestedIndex={index}
                        />
                        <div
                          className={classNames(
                            'border-b border-dashed border-borderLight-500 dark:border-borderDark-500 mx-4'
                          )}
                        />
                      </div>
                    )
                  })}
                </motion.div>
              )}
            </AnimatePresence>
            {membersWithoutRepresentative.length > 0 && viewMoreButton}

            {isMoreVisible && (
              <div
                className={classNames(
                  'border-b-4 dark:border-borderLight-500 border-borderDark-500'
                )}
              />
            )}
          </div>
        </div>
        <div
          className={classNames(
            'border-b border-borderLight-500 dark:border-borderDark-500 mx-4',
            {
              'border-b-0': isLast,
            }
          )}
        />
      </div>
    </div>
  )
}

const classes = (scape?: BackgroundScheme | ColorScheme) => {
  const textClass = scape
    ? scape.scheme === 'dark'
      ? 'text-white'
      : 'text-black'
    : 'text-black dark:text-white'

  const captionClass = scape
    ? scape.scheme === 'dark'
      ? 'text-white/20'
      : 'text-black/20'
    : 'text-black/20 dark:text-white/20'

  const fontNameTextClass = scape
    ? scape.scheme === 'dark'
      ? 'text-white/50'
      : 'text-black/50'
    : 'text-black/50 dark:text-white/50'

  return {
    textClass,
    captionClass,
    fontNameTextClass,
  }
}

const ItemContent: FC<{
  font: FontDescriptor
  showFullName?: boolean
  className?: string
  isNested?: boolean
  nestedIndex?: number
}> = ({ font, className, nestedIndex, showFullName = false, isNested }) => {
  const backgroundColor = useStore(
    (state) => getColorSchemeByFontId(state, font.id),
    shallow
  )

  const backgroundImage = useStore(
    (state) => getBackgroundImageByFontId(state, font.id),
    shallow
  )

  const scape = backgroundImage ?? backgroundColor
  const text = useStore((state) => state.pangram)
  const prevText = usePrevious(text)
  const { textClass, captionClass, fontNameTextClass } = classes(scape)
  const isCustomText = !pangrams.includes(text)
  const fontStyles = getFontStyle(font.style)
  const [isVisible, setVisible] = useState(false)
  const textfitRef = useRef<HTMLDivElement>(null)
  const timesFitted = useRef(0)
  const fontSize = useStore((state) => state.fontSize)

  const { ref: refreshObserverRef, value: deferredValue } =
    useObserverRefreshValue(fontSize)

  const { ref, width } = useResizeObserver({
    box: 'border-box',
  })

  const { ref: observerRef, inView } = useInView({
    rootMargin: '2000px 0px',
    triggerOnce: true,
    onChange(inView) {
      if (inView) {
        setSeen(true)
        setTimeout(() => {
          fit()
          setTimeout(() => {
            setVisible(true)
          }, 200)
        }, 10)
      }
    },
  })

  const [seen, setSeen] = useState(false)

  const fit = useCallback(() => {
    if (textfitRef.current) {
      textfit(textfitRef.current, {
        widthOnly: true,
        maxFontSize: 1000,
      })

      timesFitted.current++
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onResize = useCallback(
    throttle(
      () => {
        fit()
        // console.log('resized')
      },
      300,
      { trailing: true }
    ),
    []
  )

  useEffect(() => {
    if (prevText === undefined) return

    if (seen && prevText !== text) {
      setVisible(false)

      setTimeout(() => {
        fit()
        setTimeout(() => {
          setVisible(true)
        }, 10)
      }, 10)
    }
  }, [setVisible, text, fit, prevText, seen])

  useEffect(() => {
    const wrap = () => {
      if (inView) onResize()
    }

    window.addEventListener('resize', wrap)

    return () => {
      window.removeEventListener('resize', wrap)
    }
  }, [inView, onResize])

  useEffect(() => {
    const onChange = () => {
      if (inView) setVisible(false)
    }

    const onSelectEnd = (isSame: boolean) => {
      console.log('memes')
      if (isSame) {
        setVisible(true)
      }
    }

    Bus.on('pangram:prechange', onChange)
    Bus.on('pangram:selectend', onSelectEnd)

    return () => {
      Bus.off('pangram:prechange', onChange)
      Bus.off('pangram:selectend', onSelectEnd)
    }
  }, [inView, setVisible])

  const fontFamily = config.isWindows ? font.fullName : font.postscriptName
  const bgImage = backgroundImage?.['grid-1']
  const [loaded, setLoaded] = useState<Record<string, boolean>>({})

  return (
    <div
      className={classNames(
        layoutClass,
        className,
        'fittedGridItem relative controls-activator min-h-[140px] xl:min-h-[180px]'
      )}
      style={{
        opacity: isVisible ? 1 : 0,
        backgroundColor: backgroundColor?.backgroundColor,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
      ref={applyRefs(refreshObserverRef, observerRef)}
    >
      <AnimatePresence>
        {bgImage && (
          <picture key={bgImage.src}>
            <source type="image/webp" srcSet={bgImage.srcSet} />
            <motion.img
              key={bgImage.src}
              initial={{ opacity: 0 }}
              animate={{ opacity: !loaded[bgImage.src] ? 0 : 1 }}
              exit={{ opacity: 0 }}
              onLoad={() =>
                setLoaded((prev) => ({
                  ...prev,
                  [bgImage.src]: true,
                }))
              }
              src={bgImage.src}
              srcSet={bgImage.srcSet}
              transition={{ type: 'tween', duration: 0.3 }}
              loading="lazy"
              className="object-cover object-center left-0 top-0 w-full h-full absolute border-0 -z-10"
            />
          </picture>
        )}
      </AnimatePresence>
      <div ref={ref}>
        <Controls
          font={font}
          className={classNames('right-4 top-6 absolute controls')}
        />
        <div>
          <FontName
            className={classNames('pt-6 px-4', fontNameTextClass, textClass)}
          >
            <span className="inline-block">
              {showFullName
                ? font.fullName ?? font.family
                : font.family ?? font.fullName}
            </span>
            {isNested && (
              <span className={captionClass}>
                {' - '}
                {fontStyles?.fontWeight}
              </span>
            )}
          </FontName>
          {typeof width !== 'undefined' && (
            <div style={{ width: width - 4 }} className="px-6">
              {isCustomText ? (
                <div
                  className={classNames('py-6 text-center', textClass)}
                  style={{
                    fontFamily: config.backupFont,
                    lineHeight: 1,
                    fontSize: deferredValue,
                    fontStyle: fontStyles?.fontStyle,
                    fontWeight: (fontStyles?.fontWeight ?? '').toString(),
                  }}
                >
                  <FontWait
                    style={{
                      fontFamily: seen ? fontFamily : '',
                    }}
                  >
                    {text}
                  </FontWait>
                </div>
              ) : (
                // <TextFit
                //   isSeen={seen}
                //   text={text}
                //   postscriptName={font.postscriptName}
                //   containerWidth={width - 72}
                //   fontWeight={fontStyles?.fontWeight}
                //   fontStyle={fontStyles?.fontStyle}
                // />

                <div
                  style={{
                    fontFamily: config.backupFont,
                    opacity: isVisible ? 1 : 0,
                    transition: 'opacity 250ms',
                    transitionDelay: isNested
                      ? `${(nestedIndex ?? 0) * 5}ms`
                      : '0ms',
                  }}
                >
                  <div
                    ref={textfitRef}
                    style={{
                      lineHeight: 1,
                      fontStyle: fontStyles?.fontStyle,
                      fontWeight: (fontStyles?.fontWeight ?? '').toString(),
                      fontFamily: seen ? fontFamily : '',
                    }}
                    className={classNames(
                      'whitespace-nowrap py-6 overflow-hidden ',
                      textClass
                    )}
                  >
                    {text}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

// const NoRerender = React.memo(
//   forwardRef<HTMLDivElement, { children?: ReactNode }>(({ children }, ref) => {
//     return <div ref={ref}>{children}</div>
//   }),
//   () => true
// )
