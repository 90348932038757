import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { Header } from '@/components/Header'
import { LetterViewLayout } from '@/components/layouts/LetterView'
import { useStore } from '@/store'
import { GridOption } from '@/data/buttons'
import { MasonryViewLayout } from '@/components/layouts/MasonryView'
import { FittedViewLayout } from '@/components/layouts/FittedView'
import shallow from 'zustand/shallow'
import { FontData, FontDescriptor, getFontStyle, preloadImage } from './utils'
import { IntroModal } from './components/float/IntroModal'
import IconLogo from './components/icons/Logo'
import { LazyImage } from './components/LazyImage'
import { backgrounds } from './data/colors'

// import {
//   createBrowserRouter,
//   RouterProvider,
//   Route,
//   Link,
// } from 'react-router-dom'

// @ts-ignore
const supportsFontsQuery = typeof queryLocalFonts !== 'undefined'

if (!supportsFontsQuery || typeof navigator.permissions === 'undefined') {
  window.location.href = 'https://fallback.localfonts.xyz'
}

export default function App() {
  const [isBootstrapped, setBootstrapped] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      backgrounds.forEach((bg) => {
        const items = [bg['grid-1'], bg['grid-2'], bg['grid-3']]

        items.forEach(({ src, srcSet, srcSetWebp }) => {
          preloadImage(src, srcSet, [
            { type: 'image/webp', srcSet: srcSetWebp },
          ]).then(({ currentSrc }) => {
            // console.log('done', bg.id, currentSrc)
          })
        })
      })
    }, 1000)
  }, [])

  const [status, setStatus] = useState<
    null | 'granted' | 'denied' | 'prompt' | 'unavailable'
  >(
    typeof navigator.permissions === 'undefined' || !supportsFontsQuery
      ? 'unavailable'
      : null
  )

  const actions = useStore(
    (state) => ({
      setFonts: state.setFonts,
      setInitialColors: state.setInitialColors,
      generateRandomFontList: state.generateRandomFontList,
      markFontsLoaded: state.markFontsLoaded,
    }),
    shallow
  )

  const gridView = useStore((state) => state.gridView)
  const noop = {}

  const loadFonts = useCallback(async () => {
    // @ts-ignore
    if (typeof queryLocalFonts !== 'undefined') {
      try {
        console.log('querying')

        // @ts-ignore
        const fonts = (await self.queryLocalFonts()).map(
          (font: FontData): FontDescriptor => ({
            family: font.family,
            fullName: font.fullName,
            postscriptName: font.postscriptName,
            style: font.style,
            ...(getFontStyle(font.style) ?? noop),
            id: `[${String(font.family)}][${String(font.fullName)}][${String(
              font.postscriptName
            )}]`,
          })
        ) as FontDescriptor[]

        // fonts = fonts.slice(0, 5)

        // console.time('t')

        // fonts.slice(0, 10).forEach((font) => {
        //   console.time('inner')
        //   const canvas = document.createElement('canvas')
        //   calculate({
        //     postscriptName: font.postscriptName,
        //     fontWeight: 400,
        //     fontStyle: '',
        //     width: 1400,
        //     context: canvas.getContext('2d')!,
        //     text: 'Pack my box with five dozen liquor jugs.',
        //   })
        //   console.timeEnd('inner')
        // })

        // console.timeEnd('t')
        console.log(fonts)

        const status = await navigator.permissions.query({
          name: 'local-fonts' as any,
        })

        if (status.state === 'granted') {
          setStatus('granted')

          // console.log(fonts.slice(0, 14) as any)
          actions.setFonts(fonts as any)
          const fontsRandomized = useStore.getState().fontsRandomized

          if (fontsRandomized.length === 0) {
            console.log('setting colors')
            actions.setInitialColors()
          }

          if (
            fontsRandomized.length !== fonts.length ||
            fontsRandomized.length === 0
          ) {
            actions.generateRandomFontList()
          }

          actions.markFontsLoaded()
          setBootstrapped(true)
        }
      } catch (err) {
        console.log(err)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let layout: ReactNode

  console.log({ supportsFontsQuery, isBootstrapped, status })

  let content

  useEffect(() => {
    ;(async () => {
      if (typeof navigator.permissions === 'undefined') {
        return
      }

      const status = await navigator.permissions.query({
        name: 'local-fonts' as any,
      })

      if (status.state === 'granted') loadFonts()
      else if (status.state === 'denied') setStatus('denied')
      else if (status.state === 'prompt') setStatus('prompt')
    })()
  }, [loadFonts])

  if (status === null) return null
  else if (status === 'unavailable') {
    content = (
      <div className="fixed top-0 left-0 w-full h-full py-4 px-4 text-xl z-overlay bg-white dark:bg-dark2-500">
        <LazyImage
          src="/yeah.jpg"
          className="object-cover left-0 top-0 w-full h-full fixed -z-10"
        />
        <IconLogo className="w-[42px]" />
        <div className="top-[30%] left-1/2 -translate-x-1/2 -translate-y-1/2 absolute w-full  dark:text-white px-4">
          <div className="max-w-[353px] pt-6 mx-auto text-center">
            <h2 className="text-5xl font-900 mb-8 lg:mb-6">Almost there!</h2>
            <p className="max-w-[353px] text-neutral-400 dark:text-neutral-100 text-sm">
              To browse Localfonts you need a browser that supports querying
              local fonts. Please use Chrome, Edge, or Brave browsers in your
              desktop.
            </p>
          </div>
        </div>
      </div>
    )
  } else if (status === 'denied') {
    content = (
      <div className="px-4">
        <IconLogo className="w-[42px]" />
        <div className="fixed w-full h-full top-0 left-0 bg-white z-overlay">
          <LazyImage
            src="/yeah.jpg"
            className="object-cover left-0 top-0 w-full h-full fixed -z-10"
          />

          <div className="top-[30%] left-1/2 -translate-x-1/2 -translate-y-1/2 absolute w-full  dark:text-white px-4">
            <div className="max-w-[353px] pt-6 mx-auto text-center ">
              <h2 className="text-5xl font-900 mb-6">Almost there!</h2>
              <p className="max-w-[353px] text-neutral-400 dark:text-neutral-100 text-sm">
                You have blocked access for this website to access your fonts.
                You will need to go into your {`browser's`} settings and allow
                this site to access your fonts.
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  } else if (status === 'prompt') {
    content = (
      <div>
        <button
          className="fixed w-full h-full top-0 left-0 bg-white z-overlay px-4"
          onClick={() => loadFonts()}
        >
          <div>
            <svg
              className="splash-svg mx-auto max-w-[512px]"
              viewBox="0 0 512 448"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                className="rect"
                width="512"
                height="448"
                rx="50"
                fill="#000000"
              />
              <path
                d="M161 210.715C161 207.716 162.283 204.86 164.524 202.867L208.024 164.201C212.002 160.664 217.998 160.664 221.976 164.201L265.476 202.867C267.717 204.86 269 207.716 269 210.715V267.5C269 273.299 264.299 278 258.5 278H171.5C165.701 278 161 273.299 161 267.5V210.715Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M293 140C293 136.686 295.686 134 299 134H317H329H347C350.314 134 353 136.686 353 140V140C353 143.314 350.314 146 347 146H335C331.686 146 329 148.686 329 152V284C329 287.314 331.686 290 335 290H347C350.314 290 353 292.686 353 296V296C353 299.314 350.314 302 347 302H329H317H299C295.686 302 293 299.314 293 296V296C293 292.686 295.686 290 299 290H311C314.314 290 317 287.314 317 284V152C317 148.686 314.314 146 311 146H299C295.686 146 293 143.314 293 140V140Z"
                fill="white"
              />
            </svg>
            <p className="tracking text-sm font-bold pt-8">
              (Press the button to allow access to your fonts.)
            </p>
          </div>
        </button>
      </div>
    )
  } else if (status === 'granted') {
    if (gridView === GridOption.masonry) {
      layout = <MasonryViewLayout />
    } else if (gridView === GridOption.square) {
      layout = <LetterViewLayout />
    } else {
      layout = <FittedViewLayout />
    }

    content = (
      <div>
        {layout}
        <IntroModal></IntroModal>
      </div>
    )
  }

  return (
    <div>
      <Header />
      <div className="max-w-[3840px] mx-auto">{content}</div>
    </div>
  )
}
